// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allowed-users-form {
  position: relative;
  display: block;
  width: 70%;
  margin: auto;
}
.allowed-users-form__button {
  margin-top: 1rem;
}

.allowed-users-list__item {
  margin-bottom: 0.5rem;
}
.allowed-users-list__button {
  margin-left: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/AllowedUsers.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,UAAA;EACA,YAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAIE;EACE,qBAAA;AADJ;AAIE;EACE,mBAAA;AAFJ","sourcesContent":[".allowed-users-form {\n  position: relative;\n  display: block;\n  width: 70%;\n  margin: auto;\n\n  &__button {\n    margin-top: 1rem;\n  }\n}\n\n.allowed-users-list {\n  &__item {\n    margin-bottom: .5rem;\n  }\n\n  &__button {\n    margin-left: .5rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
