import React, { useContext } from "react";
import { Link } from "react-router-dom";
import CheckSession from "./CheckSession";
import { UserTokenContext } from "./App";
import axios from "axios";
import { apiUrl } from "../js/context";
import { createAuthHeaders, getSession } from "../js/functions";

export default function Dashboard() {
  const loggedUserkey = useContext(UserTokenContext);

  function execIndexers() {
    execAdminEndpoint("exec-indexer");
  }

  function filterDuplicates() {
    execAdminEndpoint("filter-trophies-duplicates");
  }

  function cleanCompetitionNames() {
    execAdminEndpoint("clean-competition-names");
  }

  function uniformUsersFullNames() {
    execAdminEndpoint("uniform-users-full-names");
  }

  function updateTrophiesAllUsers() {
    execAdminEndpoint("admin-update-trophies-all-users");
  }

  async function execAdminEndpoint(endpoint) {
    try {
      const loggerUserKey = getSession();
      const headers = createAuthHeaders(loggerUserKey);
      console.log(headers);
      await axios.post(apiUrl + "/" + endpoint, {}, { headers });
      alert("Done");
    } catch (err) {
      alert("Something went wrong...");
      console.log(err);
    }
  }

  return (
    <>
      <CheckSession session={loggedUserkey} />
      <h1>Mitali Dashboard</h1>
      <button onClick={execIndexers}>Index all trophies</button>
      <button onClick={cleanCompetitionNames}>Clean competition names</button>
      <button onClick={uniformUsersFullNames}>Uniform users full names</button>
      <button onClick={updateTrophiesAllUsers}>Update all users</button>
      <button onClick={filterDuplicates}>
        Filter duplicated trophies & competitions
      </button>
      <ul>
        <li>
          <Link to="/add-trophy">Give a trophy to a user</Link>
        </li>
        <li>
          <Link to="/allowed-users">Add allowed users (for testing)</Link>
        </li>
        <li>
          <Link to="/create-discipline">Create a discipline</Link>
        </li>
        <li>
          <Link to="/update-users">Update users</Link>
        </li>
        <li>
          <Link to="/add-competition-pics-url">Add competition pics url</Link>
        </li>
      </ul>
    </>
  );
}
