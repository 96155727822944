// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notification {
  display: block;
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  animation: Notification-appear 2.5s 1 forwards;
  padding: 0.5rem;
  border-radius: 7px;
  color: #f5eccd;
  background-color: rgba(0, 0, 0, 0.6);
}

@keyframes Notification-appear {
  0% {
    transform: translateY(4rem) translateX(-50%);
  }
  20% {
    transform: translateX(-50%);
  }
  80% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateY(4rem) translateX(-50%);
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Notification.scss","webpack://./src/assets/styles/GlobalVars.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,eAAA;EACA,YAAA;EACA,SAAA;EACA,2BAAA;EACA,8CAAA;EACA,eAAA;EACA,kBAAA;EACA,cCRM;EDSN,oCAAA;AADF;;AAIA;EACE;IACE,4CAAA;EADF;EAIA;IACE,2BAAA;EAFF;EAKA;IACE,2BAAA;EAHF;EAMA;IACE,4CAAA;EAJF;AACF","sourcesContent":["@import \"./GlobalVars.scss\";\n\n.Notification {\n  display: block;\n  position: fixed;\n  bottom: 1rem;\n  left: 50%;\n  transform: translateX(-50%);\n  animation: Notification-appear 2.5s 1 forwards;\n  padding: 0.5rem;\n  border-radius: 7px;\n  color: $white;\n  background-color: rgba(0, 0, 0, 0.6);\n}\n\n@keyframes Notification-appear {\n  0% {\n    transform: translateY(4rem) translateX(-50%);\n  }\n\n  20% {\n    transform: translateX(-50%);\n  }\n\n  80% {\n    transform: translateX(-50%);\n  }\n\n  100% {\n    transform: translateY(4rem) translateX(-50%);\n  }\n}\n","$main_color: #2930f2ff;\n$cool_gray: #a7a5c6ff;\n$yellow: #e3c500ff;\n$white: #f5eccdff;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
