import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { FiEye, FiEyeOff } from "react-icons/fi";
import ValidateMuiTextField from "./ValidateMuiTextField";

export default function PasswordMuiTextField({
  wrapperClassName,
  innerClassName,
  name,
  required,
  onChange,
  onError,
  sx = {},
  maxWidth = false
}) {
  const [showPassword, setShowPassword] = useState(false);
  const type = showPassword ? "text" : "password";
  const changeVisibilityIcon = showPassword ? <FiEyeOff /> : <FiEye />;
  function changeVisibility() {
    setShowPassword(!showPassword);
  }


  return (
    <ValidateMuiTextField
      label="Password"
      type={type}
      name={name}
      onChange={onChange}
      onError={onError}
      required={required}
      sx={sx}
      maxWidth={maxWidth}
      variant="outlined"
      className={wrapperClassName}
      errorMsg="Password must be, at least 7 chars long."
      InputProps={{
        className: { innerClassName },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={changeVisibility}>
              {changeVisibilityIcon}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
