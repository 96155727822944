// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  display: block;
  margin: auto;
  width: 80%;
}
.login-form__text-input-wrapper {
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 1rem;
}
.login-form__text-input-mui-layer {
  width: 100%;
}
.login-form__text-input {
  position: relative;
}
.login-form__choose-labels {
  position: relative;
  display: block;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/App.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,UAAA;AACF;AACE;EACE,kBAAA;EACA,WAAA;EACA,cAAA;EACA,mBAAA;AACJ;AAEE;EACE,WAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;AAIE;EACE,kBAAA;EACA,cAAA;EACA,mBAAA;AAFJ","sourcesContent":[".login-form {\n  display: block;\n  margin: auto;\n  width: 80%;\n\n  &__text-input-wrapper {\n    position: relative;\n    width: 100%;\n    display: block;\n    margin-bottom: 1rem;\n  }\n\n  &__text-input-mui-layer {\n    width: 100%;\n  }\n\n  &__text-input {\n    position: relative;\n  }\n\n  &__choose-labels {\n    position: relative;\n    display: block;\n    margin-bottom: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
