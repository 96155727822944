import React, { useState, useRef, useEffect, useContext } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  Chip,
  Button,
} from "@mui/material";

import "../styles/CreateDiscipline.scss";
import Centerer from "./Centerer";
import axios from "axios";
import { apiUrl } from "../js/context";
import CheckSession from "./CheckSession";
import { UserTokenContext } from "./App";
import { createAuthHeaders } from "../js/functions";

export default function CreateDiscipline() {
  const loggedUserKey = useContext(UserTokenContext);
  const nameRef = useRef();

  const [formData, setFormData] = useState({
    created_by: "64cb9269076a0dc3b8ba759d",
  });

  console.log(formData);

  /* useEffect(() => {
    nameRef.current.addEventListener("change", (e) => fetchDisciplineData(e));
  }); */

  async function fetchDisciplineData(e) {
    const disciplineName = e.target.value;
    const { data: savedDisciplineData } = await axios.get(
      apiUrl + "/discipline-data/" + disciplineName
    );

    setFormData(savedDisciplineData);
  }

  function validateURLs(urls) {
    let newUrls = [];
    function removeFinalSlashes(site) {
      return site.replace(/\/{1,}$/, "");
    }

    for (let i = 0; i < urls.length; i++) {
      let url = urls[i];
      url = removeFinalSlashes(url);
      if (url.match(/https?:\/\//i)) {
        if (!url.split("://")[1]) return undefined;
        url = url.split("://")[1];
      }

      newUrls.push(url.toLowerCase());
    }

    return newUrls.filter((url) =>
      url.match(
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
      )
    );
  }

  function updateForm(e, newValue) {
    console.log(e.target.name);
    const name = e?.target?.name;
    if (!name) return;
    let value = newValue;
    if (!value) value = e?.target?.value;
    if (name === "data_websites") {
      value = validateURLs(value);
    }
    setFormData({ ...formData, ...{ [name]: value } });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!formData.data_websites || !formData.name) {
      alert("Data websites list missing");
      return;
    }

    if (!formData.data_websites.length || !formData.name.length) {
      alert("Data websites list missing");
      return;
    }

    formData.data_websites = validateURLs(formData.data_websites);
    const httpConfig = {
      headers: createAuthHeaders(loggedUserKey),
    };
    const { status } = await axios.post(
      apiUrl + "/create-discipline",
      formData,
      httpConfig
    );

    if (!status) {
      alert("Something went wrong");
      return;
    }

    if (status === 200) {
      alert("Done!");
      window.location.reload();
    }
  }

  return (
    <>
      <CheckSession session={loggedUserKey} />
      <form className="CreateDiscipline" onSubmit={handleSubmit}>
        <TextField
          id="new-discipline-name"
          name="name"
          label="Discipline name"
          variant="outlined"
          onChange={updateForm}
          sx={{ marginBottom: "1rem" }}
          ref={nameRef}
          className="CreateDiscipline__input"
        />
        <TextField
          id="new-discipline-description"
          label="Description"
          variant="outlined"
          name="description"
          onChange={updateForm}
          defaultValue={formData?.description ?? ""}
          sx={{ marginBottom: "1rem" }}
          className="CreateDiscipline__input"
        />
        <Select
          id="new-discipline-type"
          onChange={updateForm}
          defaultValue={formData?.type ?? "0"}
          autoWidth
          label="Type"
          name="type"
          className="CreateDiscipline__input"
        >
          <MenuItem value={"0"}>Sport</MenuItem>
          <MenuItem value={"1"}>Art</MenuItem>
        </Select>
        <Autocomplete
          name="categories"
          multiple
          id="new-discipline-categories"
          defaultValue={formData?.categories ?? []}
          onChange={(event, newValue) => {
            updateForm(event, newValue);
          }}
          options={["Individual", "Teams"]}
          getOptionLabel={(option) => option}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Categories"
              placeholder="Categories"
            />
          )}
          className="CreateDiscipline__input"
        />
        <Autocomplete
          multiple
          id="new-discipline-data-websites"
          name="data_websites"
          options={[]}
          defaultValue={formData?.data_websites ?? ""}
          freeSolo
          onChange={updateForm}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="filled"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name="data_websites"
              variant="outlined"
              label="Data websites"
              placeholder="Data websites"
            />
          )}
          className="CreateDiscipline__input"
        />

        <Centerer>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Centerer>
      </form>
    </>
  );
}
