import React, { useState, useEffect } from "react";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";
import { apiUrl } from "../js/context.js";

export default function ChooseDisciplines({
  setValue,
  defaultValue = [],
  required,
}) {
  const [options, setOptions] = useState([]);

  async function fetchDisciplines() {
    try {
      const { data: disciplines } = await axios.get(apiUrl + "/disciplines");
      setOptions(disciplines.map((disc) => disc.name));
    } catch (err) {
      console.log(err);
      setOptions([]);
    }
  }

  useEffect(() => {
    fetchDisciplines();
  }, []);

  return (
    <Autocomplete
      id="tags-outlined"
      options={options}
      onChange={(e, value) => {
        setValue({ target: { name: "disciplines" } }, value);
      }}
      name="discipline"
      getOptionLabel={(option) => option}
      getOptionSelected={(option, value) => option === value}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Discipline"
          placeholder="Discipline"
          required={required}
        />
      )}
    />
  );
}
