import React, { useState, useEffect, createContext, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import CreateDiscipline from "./CreateDiscipline";
import AddTrophy from "./AddTrophy";
import { TextField, Button } from "@mui/material";
import axios from "axios";
import Centerer from "./Centerer";
import ForceRedirect from "./ForceRedirect";
import { apiUrl } from "../js/context";
import PasswordMuiTextField from "./PasswordMuiTextField";
import Cookies from "js-cookie";
import AllowedUsers from "./AllowedUsers";
import "../styles/App.scss";
import { getSession, saveSession } from "../js/functions";
import Dashboard from "./Dashboard";
import UpdateUsers from "./UpdateUsers";
import AddCompetitionPicsUrl from "./AddCompetitionPicsUrl";

export const UserTokenContext = createContext(undefined);
export const UserIdContext = createContext(undefined);

function CreateDisciplineRoute() {
  return (
    <>
      <h1 style={{ textAlign: "center" }}>Create Discipline</h1>
      <CreateDiscipline />
    </>
  );
}

function AddTrophyRoute() {
  return (
    <>
      <h1 style={{ textAlign: "center" }}>Add Trophy</h1>
      <AddTrophy />
    </>
  );
}

function AllowedUsersRoute() {
  return (
    <>
      <h1 style={{ textAlign: "center" }}>Allowed Users</h1>
      <AllowedUsers />
    </>
  );
}

function LoginForm({ setLoggedUserKey, setUserId }) {
  const keys = {
    form: "form",
    sent: "sent",
    logged_in: "logged_in",
    server_error: "server_error",
  };

  const [userData, setUserData] = useState({});
  const [formState, setFormState] = useState(keys.form);
  const [inputAlreadyUsed, setInputAlreadyUsed] = useState({});
  const [isThereAnyFormError, setIsThereAnyFormError] = useState(false);

  useEffect(() => {
    if (formState === keys.server_error) {
      alert("Incorrect password or username");
    }
  }, [formState, keys.server_error]);

  useEffect(() => {
    setIsThereAnyFormError(false);
  }, [userData]);

  /*
    This component, will:
    1. Render a form to let the user login
    2. Send the information to the server, while rendering a feedback animation
    3. When server returns 200 status, redirect the user to his profile page
    (4.) In case of server error, will display a feedback message
    */
  const loginForm = (
    <>
      <h1 style={{ textAlign: "center" }}>Welcome back!</h1>
      <form className="login-form" onSubmit={handleSubmit}>
        <TextField
          className="login-form__text-input-mui-layer"
          InputProps={{
            className: "login-form__text-input",
          }}
          label="Username"
          required
          onChange={setValue}
          name="username"
          variant="outlined"
          error={inputAlreadyUsed?.username ?? false}
          errorMsg={
            inputAlreadyUsed?.username ?? false
              ? "This username already used"
              : "Username can only contain letters, numbers, dots (.) and underscores (_)"
          }
          onError={alertError}
        />
        <PasswordMuiTextField
          required
          onChange={setValue}
          name="password"
          wrapperClassName="login-form__text-input-wrapper"
          innerClassName="login-form__text-input"
          maxWidth
        />
        <Centerer>
          <Button sx={{ marginTop: "2rem" }} type="submit" variant="contained">
            Login
          </Button>
        </Centerer>
      </form>
    </>
  );

  const toReturn = {
    [keys.form]: loginForm,

    [keys.sent]: (
      <section className="creating-user">
        <h1 className="creating-user__title">Logging in...</h1>
      </section>
    ),
    [keys.logged_in]: <ForceRedirect to={`/dashboard`} />,
    [keys.server_error]: <>{loginForm}</>,
  };

  async function setValue(e, value) {
    const { target } = e;
    const { name } = target;
    if (!value) {
      value = target.value;
    }

    setInputAlreadyUsed({ ...inputAlreadyUsed, ...{ [name]: false } });
    setUserData({ ...userData, ...{ [name]: value } });
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log(e);
    sendData();
  }

  function alertError() {
    setIsThereAnyFormError(true);
  }

  function areErrorsInForm() {
    return isThereAnyFormError;
  }

  async function sendData() {
    if (areErrorsInForm()) {
      alert("You need to correct some errors before you login");
      return;
    }

    setFormState(keys.sent);
    try {
      const req = await axios.post(`${apiUrl}/admin-login`, userData, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      });

      console.log(req);

      const { status, data } = req;

      console.log(status, data);
      // If everythink ok, redirect
      if (status === 200) {
        const { session_token: sessionToken, user_id: userId } = data;
        saveSession(sessionToken);
        setLoggedUserKey(sessionToken);
        setUserId(userId);
        setFormState(keys.logged_in);
        const alreadyUsed = data.already_used;
        setInputAlreadyUsed(alreadyUsed);
        return;
      }

      // If not, there's an error
      setFormState(keys.server_error);
    } catch (err) {
      console.log(err);
      setFormState(keys.server_error);
    }
  }

  return toReturn[formState];
}

function LoginRoute({ setLoggedUserKey, setUserId }) {
  return (
    <>
      <LoginForm setLoggedUserKey={setLoggedUserKey} setUserId={setUserId} />
    </>
  );
}

function DashboardRoute({ }) {
  return <Dashboard />;
}

export default function App() {
  const [loggedUserkey, setLoggedUserKey] = useState(getSession());
  const [userId, setUserId] = useState(Cookies.get("user-id"));

  return (
    <>
      <UserTokenContext.Provider value={loggedUserkey}>
        <UserIdContext.Provider value={userId}>
          <Router>
            <Routes>
              <Route
                path="/create-discipline"
                element={<CreateDisciplineRoute />}
              ></Route>
              <Route path="/add-trophy" element={<AddTrophyRoute />}></Route>
              <Route
                path="/login"
                element={
                  <LoginRoute
                    setLoggedUserKey={setLoggedUserKey}
                    setUserId={setUserId}
                  />
                }
              ></Route>
              <Route
                path="/allowed-users"
                element={<AllowedUsersRoute />}
              ></Route>

              <Route path="/update-users" element={<UpdateUsers />}></Route>
              <Route path="/add-competition-pics-url" element={<AddCompetitionPicsUrl />}></Route>
              <Route path="/dashboard" element={<DashboardRoute />}></Route>
              <Route path="/" element={<Navigate to="/dashboard" />}></Route>
            </Routes>
          </Router>
        </UserIdContext.Provider>
      </UserTokenContext.Provider>
    </>
  );
}
