import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../js/context";

import "../styles/DisplayTrophies.scss";

export default function DisplayTrophies({ getDataFromClaim }) {
  const [trophies, setTrophies] = useState([]);

  async function fetchTrophies() {
    const { data: trophies } = await axios.get(apiUrl + "/claimed-trophies");
    setTrophies(trophies);
  }

  useEffect(() => {
    fetchTrophies();
  }, []);

  return (
    <div className="DisplayTrophies">
      All trophy claims
      <ul className="DisplayTrophies__list">
        {trophies.map((trophy) => (
          <li className="DisplayTrophies__trophy">
            <button
              className="DisplayTrophies__button"
              onClick={() => getDataFromClaim(trophy)}
            >
              {`${trophy.competition_name} | ${trophy.user_position}`}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
