export function createAuthHeaders(loggedUserKey) {
  return {
    "Content-Type": "application/json",
    authorization: `Bearer ${loggedUserKey}`,
  };
}

export function addIdFieldToObject(data, id) {
  return { ...data, ...{ _id: id } };
}

export function saveSession(token) {
  localStorage.setItem("session", JSON.stringify(token));
}

export function getSession() {
  return JSON.parse(localStorage.getItem("session"));
}
