import { TextField, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Centerer from "./Centerer";
import { apiUrl } from "../js/context";
import axios from "axios";
import ChooseDisciplines from "./ChooseDisciplines";
import { createAuthHeaders, addIdFieldToObject } from "../js/functions";
import { Navigate } from "react-router-dom";

import "../styles/AddTrophy.scss";
import DisplayTrophies from "./DisplayTrophies";

import { UserTokenContext, UserIdContext } from "./App";
import CheckSession from "./CheckSession";

function AddTrophyFrom() {
  const loggedUserkey = useContext(UserTokenContext);
  const [formData, setFormData] = useState({});
  const [defaultFormValues, setDefaultFormValues] = useState({});

  console.log(defaultFormValues);

  useEffect(() => {
    setFormData(defaultFormValues);
  }, [defaultFormValues]);

  function getDataFromClaim(trophyData) {
    try {
      trophyData.date = trophyData.date.split("T")[0];
      setDefaultFormValues(trophyData);
    } catch (err) {
      setDefaultFormValues(trophyData);
    }
  }

  function checkIfDate(possibleDate) {
    try {
      console.log(possibleDate, /\d{4}-\d{2}-\d{2}/.test(possibleDate));
      return /\d{4}-\d{2}-\d{2}/.test(possibleDate);
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  function convertStringDateToTimestamp(stringDate) {
    return Math.round(new Date(stringDate).getTime() / 1000);
  }

  function handleChange(e) {
    let { name, value } = e.target;
    const isDate = checkIfDate(value);
    if (isDate) {
      value = convertStringDateToTimestamp(value);
      console.log(value);
    }
    console.log(name, value);
    setFormData({ ...formData, ...{ [name]: value } });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const headers = createAuthHeaders(loggedUserkey);
    console.log(formData);
    try {
      const { status } = await axios.post(apiUrl + "/add-trophy", formData, {
        headers,
      });

      if (status !== 200) {
        throw new Error();
      }

      alert("Trophy added correctly!");
      window.location.reload();
    } catch (err) {
      console.log(err);
      alert("Something went wrong...");
    }
  }

  return (
    <>
      <CheckSession session={loggedUserkey} />
      <DisplayTrophies getDataFromClaim={getDataFromClaim} />
      <form className="AddTrophyForm" onSubmit={handleSubmit}>
        <TextField
          className="AddTrophyForm__input"
          name="_id"
          lable="Claim id (leave blank if no claim)"
          value={defaultFormValues._id ?? ""}
          onChange={handleChange}
        />

        <TextField
          className="AddTrophyForm__input"
          required={true}
          onChange={handleChange}
          name="username"
          label="User's username"
          defaultValue={defaultFormValues.username ?? ""}
          key={defaultFormValues.username ?? ""}
          fullWidth
        />
        <TextField
          className="AddTrophyForm__input"
          sx={{ width: "100%" }}
          required={true}
          onChange={handleChange}
          name="competition_name"
          label="Competition title"
          defaultValue={defaultFormValues.competition_name ?? ""}
          key={defaultFormValues.competition_name ?? "competition_name"}
          fullWidth
        />
        <div style={{ marginBottom: "1rem" }}>
          <ChooseDisciplines
            setValue={(e, newValue) =>
              handleChange({ target: { name: "discipline", value: newValue } })
            }
            className="AddTrophyForm__input"
            sx={{ width: "100%" }}
            required={true}
            name="discipline"
            label="Discipline *"
          />
        </div>
        <TextField
          className="AddTrophyForm__input"
          sx={{ width: "100%" }}
          type="date"
          required
          onChange={handleChange}
          name="date"
          fullWidth
          defaultValue={defaultFormValues.date ?? ""}
          key={defaultFormValues.date ?? "date"}
        />
        <TextField
          className="AddTrophyForm__input"
          sx={{ width: "100%" }}
          required={true}
          onChange={handleChange}
          name="user_position"
          label="User's ranking position"
          type="number"
          fullWidth
          defaultValue={defaultFormValues.user_position ?? ""}
          key={defaultFormValues.user_position ?? "user_position"}
        />
        <TextField
          className="AddTrophyForm__input"
          sx={{ width: "100%" }}
          onChange={handleChange}
          name="image"
          label="Picture url"
          fullWidth
        />

        <Centerer>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Centerer>
      </form>
    </>
  );
}

export default function AddTrophy() {
  return <AddTrophyFrom />;
}
