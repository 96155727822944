import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { apiUrl } from "../js/context";
import axios from "axios";
import Centerer from "./Centerer";
import { Navigate } from "react-router-dom";
import "../styles/AllowedUsers.scss";
import { FiRefreshCcw, FiTrash, FiTrash2 } from "react-icons/fi";
import { createAuthHeaders } from "../js/functions";
import { UserTokenContext } from "./App";
import CheckSession from "./CheckSession";
import Notification from "./Notification";
import { v4 as uuid } from "uuid";

export default function UpdateUsers() {
  const loggedUserKey = useContext(UserTokenContext);
  const [usersList, setUsersList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  async function fetchAllUsers() {
    const headers = createAuthHeaders(loggedUserKey);
    console.log(headers);
    const { data } = await axios.get(apiUrl + "/all-users", { headers });
    setUsersList(data);
  }

  useEffect(
    function () {
      try {
        fetchAllUsers();
      } catch (err) {
        console.log("Unable to fetch users");
        alert("Something went wrong...");
      }
    },
    [loggedUserKey]
  );

  async function handleUpdateUser(id) {
    try {
      const postData = { id: id };
      const headers = createAuthHeaders(loggedUserKey);

      const { status } = await axios.post(
        apiUrl + "/admin-update-trophies-user",
        postData,
        { headers }
      );

      if (status !== 200) {
        throw new Error();
      }
    } catch (err) {
      console.log(id);
      console.log(err);
      console.log("Error updating user");
    }
  }

  return (
    <>
      {showPopup ?? <Notification>Options saved</Notification>}
      <CheckSession session={loggedUserKey} />
      <section>
        <h2>Update users trophies</h2>
      </section>
      <section>
        <ul className="users-list">
          {usersList.map((user) => (
            <li className="users-list__item" key={uuid()}>
              {user.username} ({user.full_name})
              <button onClick={() => handleUpdateUser(user._id)}>
                <FiRefreshCcw />
              </button>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
}
