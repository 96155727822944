import React, { useEffect, useState } from "react";
import { TextField, useScrollTrigger } from "@mui/material";

// Rule must be a function, that can return true or false
export default function ValidateMuiTextField({
  className,
  InputProps,
  label,
  variant,
  rule = () => true,
  errorMsg,
  error,
  name,
  type,
  required,
  onChange,
  setValue,
  onBlur,
  defaultValue,
  sx = {},
  onError = () => {},
}) {
  const [currentValue, setCurrentValue] = useState("");
  const isError = (currentValue.length && !rule(currentValue)) || error;
  const displayErrorMsg = isError ? errorMsg : "";

  useEffect(() => {
    if (!rule(currentValue)) {
      return;
    }

    if (setValue) {
      setValue(name, currentValue);
      return;
    }

    if (onBlur) {
      onBlur(name, currentValue);
      return;
    }
  }, [currentValue]);

  if (isError) {
    onError(name);
  }

  return (
    <div className="register-form__text-input-wrapper">
      <TextField
        error={error}
        helperText={displayErrorMsg}
        className={className}
        InputProps={InputProps}
        label={label}
        required={required}
        variant={variant}
        name={name}
        sx={sx}
        defaultValue={defaultValue}
        type={type}
        onChange={(e) => {
          setCurrentValue(e.target.value);
          if (onChange) {
            onChange(e);
            return;
          }
        }}
        onBlur={(e) => {
          setCurrentValue(e.target.value);
        }}
      />
    </div>
  );
}
