import React from "react";


/**
 * Redirect via JavaScript.
 * Sometimes, we have troubles with Router Navigate, so that's
 * a more robust option.
 * @param {string} to The url where we have to redirect to 
 * @returns Nothing, we only redirect
 */
export default function ForceRedirect({ to }) {
  window.location.href = to;
  return <></>;
}
