import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { apiUrl } from "../js/context";
import axios from "axios";
import Centerer from "./Centerer";
import { Navigate } from "react-router-dom";
import "../styles/AllowedUsers.scss";
import { FiRefreshCcw, FiTrash, FiTrash2 } from "react-icons/fi";
import { createAuthHeaders } from "../js/functions";
import { UserTokenContext } from "./App";
import CheckSession from "./CheckSession";
import Notification from "./Notification";
import { v4 as uuid } from "uuid";

export default function AllowedUsers() {
  const loggedUserKey = useContext(UserTokenContext);
  const [formData, setFormData] = useState({});
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [removedItem, setRemovedItem] = useState(false);
  const [allowOnlyThisList, setAllowOnlyThisList] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  async function fetchAllowOnlyThisList() {
    try {
      const { data: checked } = await axios.get(apiUrl + "/allow-only-users");
      setAllowOnlyThisList(!!checked);
    } catch (err) {
      console.log(err);
      setAllowOnlyThisList(false);
    }
  }

  useEffect(() => {
    fetchAllowOnlyThisList();
    console.log(allowOnlyThisList);
  }, []);

  useEffect(() => {
    if (!showPopup) return;
    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  }, [showPopup]);

  async function fetchAllowedUsers() {
    const { data: fetchedAllowedUsers } = await axios.get(
      apiUrl + "/allowed-users"
    );
    setAllowedUsers(fetchedAllowedUsers);
  }

  useEffect(() => {
    if (removedItem) return;
    fetchAllowedUsers();
    setRemovedItem(true);
  }, [removedItem]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, ...{ [name]: value } });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const headers = createAuthHeaders(loggedUserKey);
      const { status } = await axios.post(
        apiUrl + "/add-allowed-user",
        formData,
        { headers }
      );

      if (status !== 200) {
        throw new Error();
      }

      window.location.reload();
    } catch (err) {
      alert("Something went wrong");
      return;
    }
  }

  async function handleDeleteAllowedUser(id) {
    const confirm = window.confirm("Do you want to delete this user?");
    if (!confirm) return;
    const postData = { _id: id };
    const headers = createAuthHeaders(loggedUserKey);
    try {
      const { status } = await axios.post(
        apiUrl + "/remove-allowed-user",
        postData,
        { headers }
      );

      if (status !== 200) {
        throw new Error();
      }

      setRemovedItem(false);
    } catch (err) {
      console.log(err);
      alert("Something went wrong...");
      return;
    }
  }

  async function handleChangeOnlyAllowUsers(e) {
    const onlyAllowUsers = e.target.checked;
    try {
      const headers = createAuthHeaders(loggedUserKey);
      const data = { env: "prod", allow_only_users: onlyAllowUsers };
      console.log(data);
      const { status } = await axios.post(
        apiUrl + "/set-allow-only-users",
        data,
        { headers }
      );
      if (status !== 200) {
        throw new Error();
      }

      setAllowOnlyThisList(onlyAllowUsers);
    } catch (err) {
      setShowPopup(true);
    }
  }

  return (
    <>
      {showPopup ?? <Notification>Options saved</Notification>}
      <CheckSession session={loggedUserKey} />
      <section>
        <form className="allowed-users-form" onSubmit={handleSubmit}>
          <TextField
            onChange={handleChange}
            name="full_name"
            label="Full name"
            fullWidth
          />
          <Centerer>
            <Button
              className="allowed-users-form__button"
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </Centerer>
        </form>
      </section>
      <section>
        <h2>Public access</h2>
        <Switch
          onChange={handleChangeOnlyAllowUsers}
          checked={allowOnlyThisList}
        />
      </section>
      <section>
        <ul className="allowed-users-list">
          {allowedUsers.map((user) => (
            <li className="allowed-users-list__item" key={uuid()}>
              {user.full_name}
              <button
                className="allowed-users-list__button"
                onClick={() => handleDeleteAllowedUser(user._id)}
              >
                <FiTrash2 />
              </button>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
}
