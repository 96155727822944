import React, { useState, useRef, useEffect, useContext } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  Chip,
  Button,
} from "@mui/material";

import "../styles/CreateDiscipline.scss";
import Centerer from "./Centerer";
import axios from "axios";
import { apiUrl } from "../js/context";
import CheckSession from "./CheckSession";
import { UserTokenContext } from "./App";
import { createAuthHeaders } from "../js/functions";

export default function AddCompetitionPicsUrl() {
  const loggedUserKey = useContext(UserTokenContext);
  const nameRef = useRef();

  const [formData, setFormData] = useState({
    created_by: "64cb9269076a0dc3b8ba759d",
  });

  console.log(formData);

  function updateForm(e, newValue) {
    console.log(e.target.name);
    const name = e?.target?.name;
    if (!name) return;
    let value = newValue;
    if (!value) value = e?.target?.value;

    setFormData({ ...formData, ...{ [name]: value } });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!formData.url || !formData.competition_name) {
      alert("Some data is missing");
      return;
    }

    const httpConfig = {
      headers: createAuthHeaders(loggedUserKey),
    };
    const { status } = await axios.post(
      apiUrl + "/add-competition-pics-url",
      formData,
      httpConfig
    );

    if (!status) {
      alert("Something went wrong");
      return;
    }

    if (status === 200) {
      alert("Done!");
      window.location.reload();
    }
  }

  return (
    <>
      <CheckSession session={loggedUserKey} />
      <form className="CreateDiscipline" onSubmit={handleSubmit}>
        <TextField
          id="new-discipline-name"
          name="competition_name"
          label="Competition name"
          variant="outlined"
          onChange={updateForm}
          sx={{ marginBottom: "1rem" }}
          className="CreateDiscipline__input"
        />
        <TextField
          id="new-discipline-description"
          label="Pics Url"
          variant="outlined"
          name="url"
          onChange={updateForm}
          defaultValue={formData?.description ?? ""}
          sx={{ marginBottom: "1rem" }}
          className="CreateDiscipline__input"
        />

        <Centerer>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Centerer>
      </form>
    </>
  );
}
