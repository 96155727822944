// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DisplayTrophies {
  padding: 1rem 2rem;
}
.DisplayTrophies__list {
  list-style-type: none;
}
.DisplayTrophies__trophy {
  display: block;
  margin-bottom: 0.2rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/DisplayTrophies.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,qBAAA;AACJ;AAEE;EACE,cAAA;EACA,qBAAA;AAAJ","sourcesContent":[".DisplayTrophies {\n  padding: 1rem 2rem;\n\n  &__list {\n    list-style-type: none;\n  }\n\n  &__trophy {\n    display: block;\n    margin-bottom: 0.2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
